import React, { useState } from 'react'
import mobiscroll from '@mobiscroll/react'
import i18n from 'i18next'
import moment from 'moment'
import StaigeTag from '../../../components/styles/Tag'
import StaigeSwitch from '../../../components/styles/Switch'
import soccerfield from './img/soccerfield.svg'
import camera from './img/camera.svg'
import arrow from './img/arrow.svg'
import StaigeTooltip from '../../../components/styles/Tooltip'

import Paywall, { PaywallData } from '../Container/paywall'
import { EventTypes, stream, streamTraining } from '../Overviews/eventTypes'
import { checkLanguages, getLanguesFormat } from '../../helper/checkLanguages/checkLanguages'
import { Select } from '@mobiscroll/react5'
import { FreeTicket } from '@soccerwatch/common'

import './EventStep.scss'

export const EventStep = (props: {
  setPaywall: (price: number, freeTickets: Array<FreeTicket>) => void
  paywall: PaywallData
  paywallActive: boolean
  paywallAGB: boolean
  handlePaywallAGB: (paywallAGB: boolean) => void
  handlePaywallActive: () => void

  connectionOverlay: boolean
  handleConnectionOverlay: () => void

  isNew: boolean
  paywallDataLoaded: boolean
  date: string
  onSelectDate: (date: string) => void
  startingTime: string
  onSelectStartingTime: (time: string) => void
  duration: number
  onChangeDuration: (value: number) => void
  prohibitAudioComments: boolean
  onChangeDisabledAudioComments: any
  disabled: boolean
  privateActive: boolean | undefined
  onChangePrivateActive: (newPrivateActive: boolean, special?: boolean) => void
  onSetStreamType: (type: string) => void
  onSetEventType: (type: string) => void
  onSetCameraType: (type: string) => void
  disableTimeDate: boolean
  cameraMode: {
    streamType: string
    eventType: string
    cameraType: string
  }
  ifIpCamera: boolean
  connection: string
  onSetConnection: (value: string) => void
  enabledConnection: boolean
  specialRole: boolean
  connectionStringCheck: boolean
  nonCompetitive: boolean
  nonCompetitiveTitle: string
  onSetNonCompetitive: (type: string) => void
  onSetVideoBufferSecs: (value: number) => void
  videoBufferSecs: number | undefined
  acceptEventTypes: Array<EventTypes>
  acceptEventModes: Array<string>
  scoreboardShow: boolean
  onChangeDisabledScoreboard: any
}) => {
  const [open, setOpen] = useState<boolean>(false)

  const onSetDate = (
    event: {
      valueText?: string
    },
    inst: any
  ) => {
    if (event.valueText) {
      props.onSelectDate(event.valueText)
    }
  }

  const onSetTime = (
    event: {
      valueText?: string
    },
    inst: any
  ) => {
    if (event.valueText) {
      props.onSelectStartingTime(event.valueText)
    }
  }

  const renderCameraMode = (itm: any, index: number) => {
    const arrowActive =
      itm.value === 'cameraman' || itm.value === 'scoutingFeedSoccer' || itm.value === 'scoutingFeedIcehockey'
    const extraClassTraining = itm.value

    if (itm.onlyStreamType && !itm.onlyStreamType?.includes(props.cameraMode.eventType)) {
      return null
    }

    return (
      <div
        className={`cameraMode-box ${props.cameraMode.cameraType === itm.value && 'cameraMode-boxActive'}`}
        key={index}
        onClick={() => props.onSetCameraType(itm.value)}
      >
        <div className='box-headerTitel'>
          <div className='headerTitel-titel'>{itm.text}</div>
          <div className='tag-btn'>
            {props.cameraMode.cameraType === itm.value && <div className='btn-radio' />}
          </div>
        </div>

        <div className='box-content'>
          <div className='content-field'>
            <img src={soccerfield} />
            <div className={`field-cameraMan ${extraClassTraining}`}>
              <div className='cameraMan-arrow'>{arrowActive && <img src={arrow} />}</div>
            </div>
            <img className='field-camera' src={camera} />
          </div>
          <div className='content-infoText'>{itm.description}</div>
        </div>
      </div>
    )
  }

  return (
    <div className='main-eventStep'>
      <div className='eventStep-form'>
        <div className='form-title'>{i18n.t('matchEditor.stepEvent.date.title')}</div>
        <div className='form-dateTime'>
          <mobiscroll.Calendar
            lang={checkLanguages()}
            display='center'
            min={props.disableTimeDate ? null : moment().format(getLanguesFormat('date'))}
            onSet={onSetDate}
          >
            <mobiscroll.Input
              value={props.date}
              placeholder={i18n.t('recordingPlan.details.inputDate.placeholder')}
              disabled={props.disableTimeDate}
              style={{ margin: '0' }}
            >
              {i18n.t('recordingPlan.details.inputDate.title')}
            </mobiscroll.Input>
          </mobiscroll.Calendar>
          <mobiscroll.Time
            lang={checkLanguages()}
            steps={{ minute: 1 }}
            display='center'
            min={
              props.disableTimeDate
                ? null
                : moment(props.date, getLanguesFormat('date')).isSame(moment(), 'day')
                ? moment().add(1, 'minutes').format(getLanguesFormat('time'))
                : '00:00'
            }
            value={props.startingTime}
            onSet={onSetTime}
          >
            <mobiscroll.Input
              placeholder={i18n.t('recordingPlan.details.inputTime.placeholder')}
              disabled={props.disableTimeDate}
              style={{ margin: '0' }}
            >
              {i18n.t('recordingPlan.details.inputTime.title')}
            </mobiscroll.Input>
          </mobiscroll.Time>
        </div>
      </div>

      {props.nonCompetitive && (
        <div className='eventStep-form'>
          <mobiscroll.Input
            onChange={(event) => props.onSetNonCompetitive(event.target.value)}
            type='text'
            lang={checkLanguages()}
            value={props.nonCompetitiveTitle}
            placeholder={i18n.t('general.title') + '...'}
          >
            {i18n.t('general.title')}
          </mobiscroll.Input>
        </div>
      )}

      <div className='eventStep-form'>
        <div className='form-title'>{i18n.t('matchEditor.stepEvent.settings.title')}</div>
        <div className='form-eventType'>
          <div className='eventType-type'>
            <Select
              label={i18n.t('matchEditor.stepEvent.settings.eventType')}
              value={props.cameraMode.eventType}
              data={props.acceptEventTypes.map((v) => {
                return { value: v.value, text: v.label }
              })}
              themeVariant='dark'
              theme='ios'
              onChange={async (event, inst) => {
                const defaultEvent = stream.find((a) => a.default?.find((b) => b === event.value))
                if (defaultEvent) {
                  props.onSetCameraType(defaultEvent.value)
                } else {
                  props.onSetCameraType('cameraman')
                }
                props.onSetEventType(event.value)
              }}
            />
          </div>
          <div className='eventType-tags'>
            <StaigeTag
              label='Event'
              onClick={() => props.onSetStreamType('broadcast')}
              active={props.cameraMode.streamType === 'broadcast'}
            />
            {props.acceptEventModes.includes('training') && (
              <StaigeTag
                label='Training'
                onClick={() => props.onSetStreamType('training')}
                active={props.cameraMode.streamType === 'training'}
              />
            )}
          </div>
        </div>
        <div className='form-eventType'>
          <div style={{ width: '100%' }}>
            <mobiscroll.Input
              onChange={(event) => props.onChangeDuration(Number(event.target.value))}
              type='number'
              lang={checkLanguages()}
              value={props.duration}
              min={5}
              max={960}
            >
              {i18n.t('matchEditor.stepEvent.settings.duration')}
            </mobiscroll.Input>
            <div className='eventType-inputBottomText'>
              {i18n.t('matchEditor.stepEvent.settings.durationInfo')}
            </div>
          </div>
          <div className='eventType-tagsContainer'>
            <div className='eventType-tagsLabel'>
              {i18n.t('matchEditor.stepEvent.settings.visibilityEvent.title')}
              <StaigeTooltip title='Visibilität des Events' style={{ fontSize: 16, marginLeft: '6px' }}>
                <div className='innerDiv'>
                  {i18n.t('matchEditor.stepEvent.settings.visibilityEvent.tooltipPublic1')}{' '}
                  <a href='https://www.staige.tv' target='noopener'>
                    staige.tv
                  </a>{' '}
                  {i18n.t('matchEditor.stepEvent.settings.visibilityEvent.tooltipPublic2')} <br /> <br />
                  {i18n.t('matchEditor.stepEvent.settings.visibilityEvent.tooltipPrivat1')}{' '}
                  <a href='https://www.staige.tv' target='noopener'>
                    staige.tv
                  </a>{' '}
                  {i18n.t('matchEditor.stepEvent.settings.visibilityEvent.tooltipPrivat2')}
                </div>
              </StaigeTooltip>
            </div>
            <div className='eventType-tags'>
              <StaigeTag
                label={i18n.t('recordingPlan.advanced.duration.public')}
                onClick={() => props.onChangePrivateActive(false)}
                active={!props.enabledConnection && !props.privateActive}
              />
              <StaigeTag
                label={i18n.t('recordingPlan.advanced.duration.private')}
                onClick={() => props.onChangePrivateActive(true)}
                active={!props.enabledConnection && props.privateActive}
              />
              {props.specialRole ? (
                <StaigeTag
                  label={i18n.t('recordingPlan.advanced.duration.special')}
                  onClick={() => props.onChangePrivateActive(true, true)}
                  active={props.enabledConnection}
                />
              ) : undefined}
            </div>
          </div>
        </div>
      </div>

      {props.enabledConnection && (
        <div className='eventStep-connection'>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
            <mobiscroll.Input
              onChange={(event) => props.onSetConnection(event.target.value)}
              type='text'
              lang={checkLanguages()}
              value={props.connection}
              valid={!props.connectionStringCheck}
              errorMessage={i18n.t('recordingPlan.advanced.connection.errMsg')}
              style={{ marginTop: 0 }}
            >
              {i18n.t('recordingPlan.advanced.connection.title')}
            </mobiscroll.Input>
            <mobiscroll.Input
              onChange={(event) => props.onSetVideoBufferSecs(Number(event.target.value))}
              type='number'
              lang={checkLanguages()}
              value={props.videoBufferSecs}
            >
              {i18n.t('recordingPlan.advanced.connection.bufferSecsTitle')}
            </mobiscroll.Input>
          </div>
          <div className='activeBox-header' style={{ alignItems: 'center' }}>
            <div className='form-leftText'>{i18n.t('recordingPlan.advanced.connection.scoreOverlay')}</div>
            <div className='form-toggle'>
              <StaigeSwitch checked={props.connectionOverlay} onClick={props.handleConnectionOverlay} size='medium' />
            </div>
          </div>
        </div>
      )}

      <div className='eventStep-activeBox'>
        <div className='activeBox-header'>
          <div className='form-leftText'>{i18n.t('matchEditor.stepEvent.settings.paywall.title')}</div>
          <div className='form-toggle'>
            <StaigeSwitch checked={props.paywallActive} onClick={props.handlePaywallActive} size='medium' />
          </div>
        </div>
        {props.paywallActive && (
          <Paywall
            setPaywall={props.setPaywall}
            paywall={props.paywall}
            disabled={props.disabled}
            paywallActive={props.paywallActive}
            isNew={props.isNew}
            paywallDataLoaded={props.paywallDataLoaded}
            paywallAGB={props.paywallAGB}
            handlePaywallAGB={props.handlePaywallAGB}
          />
        )}
      </div>

      <div className='eventStep-activeBox'>
        <div
          className='activeBox-header'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setOpen(!open)
          }}
        >
          <div className='form-leftText'>{i18n.t('matchEditor.stepEvent.settings.cameraMode.title')}</div>
          <div className='form-toggle'>
            <div className='toggle-btn'>
              {open ? (
                <span className='mbsc-ic mbsc-ic-fa-angle-up' />
              ) : (
                <span className='mbsc-ic mbsc-ic-fa-angle-down' />
              )}
            </div>
          </div>
        </div>
        {open && (
          <div className='wrapper-cameraMode'>
            {props.cameraMode.streamType === 'training' &&
              streamTraining.map((itm, index) => {
                return renderCameraMode(itm, index)
              })}

            {props.cameraMode.streamType !== 'training' &&
              stream.map((itm, index) => {
                return renderCameraMode(itm, index)
              })}
          </div>
        )}
      </div>

      <div className='eventStep-toggle'>
        <div className='toggle-bar'>
          <div className='bar-title'>
            {i18n.t('matchEditor.stepEvent.settings.audio')}
            <StaigeTooltip
              text={i18n.t('matchEditor.stepEvent.settings.audioTooltip')}
              style={{ fontSize: 16 }}
            />
          </div>
          <StaigeSwitch
            size='medium'
            checked={!props.prohibitAudioComments}
            handleClick={() => props.onChangeDisabledAudioComments()}
            disabled={props.disabled}
          />
        </div>
        <div className='toggle-bar'>
          <div className='bar-title'>
            {i18n.t('matchEditor.stepEvent.settings.scoreboard')}
            <StaigeTooltip
              text={i18n.t('matchEditor.stepEvent.settings.scoreboardTooltip')}
              style={{ fontSize: 16 }}
            />
          </div>
          <StaigeSwitch
            size='medium'
            checked={props.scoreboardShow}
            handleClick={() => props.onChangeDisabledScoreboard()}
            disabled={props.disabled}
          />
        </div>
      </div>
    </div>
  )
}
